import axios from 'axios'
import config from '@/utils/config'
import { Notify, Toast } from 'vant'
const serviceUrl = require('../../public/commonIp.js')
import Aes from '@/components/aes.js'

const PRO_URL = config.PRO_URL

// 实例化axios
const instance = axios.create({
    // baseURL: DEV_URL,
    baseURL: PRO_URL,
    timeout: 10000
})

// 异常拦截处理器
const errorHandler = error => {
    if (error.response) {
        const data = error.response.data
            // 从 localstorage 获取 token
            // const token = storage.get(ACCESS_TOKEN)
            // 接口失效，401重定向到登录页
        if (error.response.status === 401) {
            // window.location = error.response.headers.redirect
        }
        if (error.response.status === 403) {
            // Notify()
            Toast.fail({
                background: '#ffe1e1',
                color: '#ad0000',
                message: 'Forbidden\n' + data.message
            })
        }
        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            // Notify({ background: '#ffe1e1', color: '#ad0000', message: '未授权\n授权验证失败' })
            // Toast.fail({
            // 	background: '#ffe1e1',
            // 	color: '#ad0000',
            // 	message: '未授权\n授权验证失败'
            // })
            // if (token) {
            // store.dispatch('Logout').then(() => {
            //   setTimeout(() => {
            //     window.location.reload()
            //   }, 1500)
            // })
            // }
        }
    }
    return Promise.reject(error)
}

// 请求拦截器
instance.interceptors.request.use(config => {
    axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
    config.headers['token'] = window.sessionStorage.getItem('token')
    config.headers['Org-Id'] = 3
    console.log(config.data)

    console.log(config.data)
        // 正式加密
    config.data = {
        data: Aes.encryptAes(JSON.stringify(config.data))
    }

    // 测试加密请求参数
    // if (window.sessionStorage.getItem('token') == null) {
    // 	config.data = Aes.encryptAes(JSON.stringify(config.data))
    // } else {
    // 	config.data = {
    // 		data: Aes.encryptAes(JSON.stringify(config.data))
    // 	}
    // }
    return config
}, errorHandler)

// 响应拦截器
instance.interceptors.response.use(res => {
        if (res.status === 200) {
            if (res.data.code == 0) {
                // 测试不加密
                // let resData = res.data
                // return resData
                // console.log(res.data)
                // 正式加密
                console.log(res.data, 'res.data响应拦截')

                let resData = ''
                if (res.data.data != null && res.data.data != '' && res.data.data != undefined) {
                    resData = Aes.decryptAes(res.data.data)
                    res.data.data = JSON.parse(resData)
                }
                return res.data
            } else {
                if (res.data.code == 'D-1001') {
                    return res.data
                } else if (res.data.code == 'D-1003') {
                    return res.data
                } else if (res.data.code == 'D-1004') {
                    return res.data
                } else {
                    if (res.data.message == null) {
                        // Toast.fail('网络问题，请稍后重试')
                    } else {
                        Toast.fail(res.data.message)
                    }
                    return false
                }
            }
        }
    }, errorHandler)
    // }),error =>{
    //     return Promise.reject(error)
    // }

export default instance