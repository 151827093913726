import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../request/request'
import config from '@/utils/config'
import store from '../store'

import { getWxAuth, patientUser } from '@/request/api'
import { goLogin } from '@/request/tool'
import { Dialog } from 'vant'
// import { join } from 'core-js/core/array'

Vue.use(VueRouter)

//解决VUE路由跳转出现Redirected when going from "/xxx" to "/yyy" via a navigation guard.报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        redirect: '/homeManage'
    },
    {
        path: '/homeManage',
        name: 'homeManage',
        meta: {
            isShow: true,
            title: '高平市中医医院'
        },
        component: () =>
            import ('../views/home/homeManage.vue')
            // children:[
            //   {
            //     path:'popup',
            //     name:'Popup',
            //     component: () => import('../views/searchPopup.vue'),
            //   }
            // ]
    },
    {
        path: '/face',
        name: 'face',
        component: () =>
            import ('../views/face.vue')
    },
    {
        path: '/popup',
        name: 'Popup',
        component: () =>
            import ('../views/searchPopup.vue')
    },
    {
        path: '/homeForm',
        name: 'homeForm',
        component: () =>
            import ('../views/home/homeForm.vue')
    },
    // tab-bar 消息
    {
        path: '/message',
        name: 'Message',
        meta: {
            isShow: true
        },
        component: () =>
            import ('../views/message/Message.vue')
            // component: () => import('../views/Category.vue'),
    },
    // 挂号科室选择(含二级)
    {
        path: '/categoryGh',
        name: 'categoryGh',
        meta: {
            isShow: false,
            title: '全部科室'
        },
        component: () =>
            import ('../views/register/Category.vue')
    },
    // 挂号科室选择(只有一级)
    {
        path: '/allDepartment',
        name: 'allDepartment',
        meta: {
            isShow: false,
            title: '全部科室'
        },
        component: () =>
            import ('../views/register/allDepartment.vue')
    },
    // 门诊缴费
    {
        path: '/OutpatientDepartmentFirst',
        name: 'OutpatientDepartmentFirst',
        meta: {
            isShow: false,
            title: '门诊缴费'
        },
        component: () =>
            import ('../views/OutpatientDepartment/OutpatientDepartmentFirst.vue')
    },
    {
        path: '/OutpatientDepartment',
        name: 'OutpatientDepartment',
        meta: {
            isShow: false,
            title: '缴费历史'
        },
        component: () =>
            import ('../views/OutpatientDepartment/OutpatientDepartment.vue')
    },
    {
        path: '/userManage',
        name: 'userManage',
        meta: {
            isShow: true
        },
        component: () =>
            import ('../views/user/userManage.vue')
    },
    // {
    // 	path: '/details',
    // 	name: 'Details',
    // 	meta: {
    // 		isShow: false
    // 	},
    // 	component: () => import('../views/Details.vue'),
    // },
    // {
    // 	path: '/brand',
    // 	name: 'Brand',
    // 	meta: {
    // 		isShow: false
    // 	},
    // 	component: () => import('../views/Brand.vue'),
    // },
    // 就诊人列表
    {
        path: '/patientManage',
        name: 'patientManage',
        meta: {
            isShow: false,
            title: '就诊人管理'
        },
        component: () =>
            import ('../views/user/patientManage.vue')
    },
    // 住院就诊人列表
    {
        path: '/patientManageAll',
        name: 'patientManageAll',
        meta: {
            isShow: false,
            title: '绑定就诊人'
        },
        component: () =>
            import ('../views/hospitalization/patientManageAll.vue')
    },
    {
        path: '/addNewPeople',
        name: 'addNewPeople',
        meta: {
            isShow: false,
            title: '识别身份证'
        },
        component: () =>
            import ('../views/user/addNewPeople.vue')
    },
    {
        path: '/addNewPeopleForm',
        name: 'addNewPeopleForm',
        meta: {
            isShow: false,
            title: '添加就诊人'
        },
        component: () =>
            import ('../views/user/addNewPeopleForm.vue')
    },
    // 挂号医生选择
    {
        path: '/register',
        name: 'register',
        meta: {
            isShow: false,
            title: '选择医生'
        },
        component: () =>
            import ('../views/register/register.vue')
    },
    // 挂号时间选择
    {
        path: '/order',
        name: 'order',
        meta: {
            isShow: false,
            title: '预约医生'
        },
        component: () =>
            import ('../views/register/order.vue')
    },
    //预约挂号确认
    {
        path: '/orderSub',
        name: 'orderSub',
        meta: {
            isShow: false,
            title: '预约信息'
        },
        component: () =>
            import ('../views/register/orderSub.vue')
    },
    // 预约生成订单    //10
    {
        path: '/orderFinish',
        name: 'orderFinish',
        meta: {
            isShow: false,
            title: '预约信息'
        },
        component: () =>
            import ('../views/register/orderFinish.vue')
    },
    // 预约结果界面
    {
        path: '/orderResult',
        name: 'orderResult',
        meta: {
            isShow: false,
            title: '预约结果'
        },
        component: () =>
            import ('../views/register/orderResult.vue')
    },
    // 预约失败界面
    // {
    // 	path: '/fail1',
    // 	name: 'fail1',
    // 	meta: {
    // 		isShow: false,
    // 		title: '预约失败'
    // 	},
    // 	component: () => import('../views/register/fail1.vue')
    // },
    {
        path: '/notice',
        name: 'notice',
        meta: {
            isShow: false,
            title: '温馨提示'
        },
        component: () =>
            import ('../views/notice.vue')
    },
    {
        path: '/successMz',
        name: 'successMz',
        meta: {
            isShow: false,
            title: '支付成功'
        },
        component: () =>
            import ('../views/paySuccess/successMz.vue')
    },
    //  住院押金充值
    {
        path: '/hospitalization',
        name: 'hospitalization',
        meta: {
            isShow: false,
            title: '押金充值'
        },
        component: () =>
            import ('../views/hospitalization/hospitalization.vue')
    },
    // 住院缴费历史查询
    {
        path: '/hospitalizationQuery',
        name: 'hospitalizationQuery',
        meta: {
            isShow: false,
            title: '综合查询'
        },
        component: () =>
            import ('../views/hospitalization/hospitalizationQuery.vue')
    },
    {
        path: '/departmentIntroduction',
        name: 'departmentIntroduction',
        meta: {
            isShow: false,
            title: '医院介绍'
        },
        component: () =>
            import ('../views/hospitalIntroduction/departmentIntroduction.vue')
    },
    {
        path: '/special',
        name: 'special',
        meta: {
            isShow: false,
            title: '特色科室介绍'
        },
        component: () =>
            import ('../views/characteristicDepartment/special.vue')
    },
    {
        path: '/specialMess',
        name: 'specialMess',
        meta: {
            isShow: false,
            title: '科室介绍'
        },
        component: () =>
            import ('../views/characteristicDepartment/specialMess.vue')
    },
    {
        path: '/departmentSpecial',
        name: 'departmentSpecial',
        meta: {
            isShow: false,
            title: '科室介绍'
        },
        component: () =>
            import ('../views/characteristicDepartment/departmentSpecial.vue')
    },
    {
        path: '/departmentDetail',
        name: 'departmentDetail',
        meta: {
            isShow: false,
            title: '科室介绍'
        },
        component: () =>
            import ('../views/characteristicDepartment/departmentDetail.vue')
    },
    {
        path: '/famousDoctor',
        name: 'famousDoctor',
        meta: {
            isShow: false,
            title: '名医介绍'
        },
        component: () =>
            import ('../views/famousDoctor/famousDoctor.vue')
    },
    {
        path: '/medicalGuide',
        name: 'medicalGuide',
        meta: {
            isShow: false,
            title: '就诊指南'
        },
        component: () =>
            import ('../views/medicalGuide/medicalGuide.vue')
    },
    {
        path: '/comprehensiveQuery',
        name: 'comprehensiveQuery',
        meta: {
            isShow: false,
            title: '综合查询'
        },
        component: () =>
            import ('../views/hospitalization/comprehensiveQuery.vue')
    },
    {
        path: '/healthEducation',
        name: 'healthEducation',
        meta: {
            isShow: false,
            title: '健康宣教'
        },
        component: () =>
            import ('../views/hospitalization/healthEducation.vue')
    },
    {
        path: '/educationDetail',
        name: 'educationDetail',
        meta: {
            isShow: false,
            title: '健康宣教'
        },
        component: () =>
            import ('../views/hospitalization/educationDetail.vue')
    },
    // 一日清单查询
    {
        path: '/oneDayList',
        name: 'oneDayList',
        meta: {
            isShow: false,
            title: '一日清单'
        },
        component: () =>
            import ('../views/hospitalization/oneDayList.vue')
    },
    // 满意度调查
    {
        path: '/satisfactionEvaluation',
        name: 'satisfactionEvaluation',
        meta: {
            isShow: false,
            title: '满意度评价'
        },
        component: () =>
            import ('../views/satisfactionEvaluation/satisfactionEvaluation.vue')
    },
    {
        path: '/satisfactionEvaluationMz',
        name: 'satisfactionEvaluationMz',
        meta: {
            isShow: false,
            title: '满意度评价'
        },
        component: () =>
            import ('../views/satisfactionEvaluation/satisfactionEvaluation.vue')
    },
    // 手术注意事项
    {
        path: '/operationNotes',
        name: 'operationNotes',
        meta: {
            isShow: false,
            title: '手术注意事项'
        },
        component: () =>
            import ('../views/hospitalization/operationNotes.vue')
    },
    {
        path: '/appointmentRecord',
        name: 'appointmentRecord',
        meta: {
            isShow: false,
            title: '预约记录'
        },
        component: () =>
            import ('../views/appointmentRecord/appointmentRecord.vue')
    },
    {
        path: '/recordMess',
        name: 'recordMess',
        meta: {
            isShow: false,
            title: '预约记录'
        },
        component: () =>
            import ('../views/recordMess.vue')
    },
    {
        path: '/operationNotesMess',
        name: 'operationNotesMess',
        meta: {
            isShow: false,
            title: '手术注意事项'
        },
        component: () =>
            import ('../views/operationNotesMess/operationNotesMess.vue')
    },
    // 挂号历史记录
    {
        path: '/jzHistory',
        name: 'jzHistory',
        meta: {
            isShow: false,
            title: '挂号历史'
        },
        component: () =>
            import ('../views/user/jzHistory.vue')
    },
    // 缴费记录
    {
        path: '/moneyRecord',
        name: 'moneyRecord',
        meta: {
            isShow: false,
            title: '缴费历史'
        },
        component: () =>
            import ('../views/user/moneyRecord.vue')
    },
    //缴费记录详情
    {
        path: '/moneyMess',
        name: 'moneyMess',
        meta: {
            isShow: false,
            title: '缴费单详情'
        },
        component: () =>
            import ('../views/user/moneyMess.vue')
    },
    // 待缴费详情
    {
        path: '/unPayDetail',
        name: 'unPayDetail',
        meta: {
            isShow: false,
            title: '待缴费单详情'
        },
        component: () =>
            import ('../views/OutpatientDepartment/unPayDetail.vue')
    },
    // 系统设置
    {
        path: '/systemSet',
        name: 'systemSet',
        meta: {
            isShow: false,
            title: '系统设置'
        },
        component: () =>
            import ('../views/user/systemSet.vue')
    },
    // 用户协议
    {
        path: '/yhxyPage',
        name: 'yhxyPage',
        meta: {
            isShow: false,
            title: '用户协议'
        },
        component: () =>
            import ('../components/common/yhxy.vue')
    },
    // 隐私政策
    {
        path: '/sxzcPage',
        name: 'sxzcPage',
        meta: {
            isShow: false,
            title: '隐私政策'
        },
        component: () =>
            import ('../components/common/ysxy.vue')
    },
    // 地址管理
    {
        path: '/address',
        name: 'address',
        meta: {
            isShow: false,
            title: '地址管理'
        },
        component: () =>
            import ('../views/user/address.vue')
    },
    {
        path: '/addAddress',
        name: 'addAddress',
        meta: {
            isShow: false,
            title: '新增地址'
        },
        component: () =>
            import ('../views/user/addAddress.vue')
    },
    // 消息设置
    {
        path: '/setMess',
        name: 'setMess',
        meta: {
            isShow: false,
            title: '系统设置'
        },
        component: () =>
            import ('../views//user/setMess.vue')
    },
    {
        path: '/dlMessage',
        name: 'dlMessage',
        meta: {
            isShow: false,
            title: '上传身份证'
        },
        component: () =>
            import ('../views/dlMessage.vue')
    },
    {
        path: '/orderTui',
        name: 'orderTui',
        meta: {
            isShow: false,
            title: '退款成功'
        },
        component: () =>
            import ('../views/orderTui.vue')
    },
    // 扫条形码获取住院号
    {
        path: '/inHospitalNo',
        name: 'inHospitalNo',
        meta: {
            isShow: false
        },
        component: () =>
            import ('../views/user/addHospitalNo.vue')
    },
    //挂号记录详情
    {
        path: '/hisMess',
        name: 'hisMess',
        meta: {
            isShow: false,
            title: '挂号历史详情'
        },
        component: () =>
            import ('../views/user/hisMess.vue')
    },
    //充值记录
    {
        path: '/payRecord',
        name: 'payRecord',
        meta: {
            isShow: false,
            title: '充值记录'
        },
        component: () =>
            import ('../views/paySuccess/payRecord.vue')
    },
    // 出入院资格查询
    {
        path: '/admissionDischargeMess',
        name: 'admissionDischargeMess',
        meta: {
            isShow: false,
            title: '住院信息'
        },
        component: () =>
            import ('../views/hospitalization/admissionDischargeMess.vue')
    },
    // 出入院资格查询
    {
        path: '/admissionDischarge',
        name: 'admissionDischarge',
        meta: {
            isShow: false,
            title: '出入院资格查询'
        },
        component: () =>
            import ('../views/hospitalization/admissionDischarge.vue')
    },
    // 信息核对
    {
        path: '/checkInfomation',
        name: 'checkInfomation',
        meta: {
            isShow: false,
            title: '信息核对'
        },
        component: () =>
            import ('../views/hospitalization/checkInfomation.vue')
    },
    // 入院成功
    {
        path: '/dischargeSuccess',
        name: 'dischargeSuccess',
        meta: {
            isShow: false,
            title: '入院成功'
        },
        component: () =>
            import ('../views/hospitalization/dischargeSuccess.vue')
    },
    // 出院结算
    {
        path: '/dischargeSettlement',
        name: 'dischargeSettlement',
        meta: {
            isShow: false,
            title: '出院结算'
        },
        component: () =>
            import ('../views/hospitalization/dischargeSettlement.vue')
    },
    // 结算成功
    {
        path: '/settlementSuccess',
        name: 'settlementSuccess',
        meta: {
            isShow: false,
            title: '结算成功'
        },
        component: () =>
            import ('../views/hospitalization/settlementSuccess.vue')
    },
    {
        path: '/reportSearch',
        name: 'reportSearch',
        meta: {
            isShow: false,
            title: '检验报告'
        },
        component: () =>
            import ('../views/OutpatientDepartment/reportSearch.vue')
    },
    {
        path: '/reportFirst',
        name: 'reportFirst',
        meta: {
            isShow: false,
            title: '检验检查报告'
        },
        component: () =>
            import ('../views/OutpatientDepartment/reportSearch.vue')
    },
    {
        path: '/departmentReport',
        name: 'departmentReport',
        meta: {
            isShow: false,
            title: '门诊报告'
        },
        component: () =>
            import ('../views/OutpatientDepartment/departmentReport.vue')
    },
    {
        path: '/reportMess',
        name: 'reportMess',
        meta: {
            isShow: false
        },
        component: () =>
            import ('../views/OutpatientDepartment/reportMess.vue')
    },
    // 病历打印
    {
        path: '/casePrinting',
        name: 'casePrinting',
        meta: {
            isShow: false,
            title: '病历打印'
        },
        component: () =>
            import ('../views/hospitalization/casePrinting.vue')
    },
    // 消息聊天框
    {
        path: '/cardChat',
        name: 'cardChat',
        meta: {
            isShow: false
        },
        component: () =>
            import ('../components/common/cardChat.vue')
    },
    {
        path: '/messMoney',
        name: 'messMoney',
        meta: {
            isShow: false,
            title: '预结算结果'
        },
        component: () =>
            import ('../views/OutpatientDepartment/messMoney.vue')
    },
    {
        path: '/payDetail',
        name: 'payDetail',
        meta: {
            isShow: false,
            title: '缴费结果'
        },
        component: () =>
            import ('../views/OutpatientDepartment/payDetail.vue')
    },
    {
        path: '/preMoney',
        name: 'preMoney',
        meta: {
            isShow: false,
            title: '缴费信息'
        },
        component: () =>
            import ('../views/OutpatientDepartment/preMoney.vue')
    },
    {
        path: '/myd',
        name: 'myd',
        meta: {
            isShow: false,
            title: '满意度评价'
        },
        component: () =>
            import ('../components/common/myd.vue')
    },
    // 电子发票
    {
        path: '/electronicInvoice',
        name: 'electronicInvoice',
        meta: {
            isShow: false,
            title: '电子发票'
        },
        component: () =>
            import ('../views/electronicInvoice/electronicInvoice.vue')
    },
    // 意见反馈
    {
        path: '/homeInfo',
        name: 'homeInfo',
        meta: {
            isShow: false,
            title: '意见反馈'
        },
        component: () =>
            import ('../views/feedback/homeInfo.vue')
    },
    {
        path: '/feedDetail',
        name: 'feedDetail',
        meta: {
            isShow: false,
            title: '意见反馈'
        },
        component: () =>
            import ('../views/feedback/feedDetail.vue')
    },
    {
        path: '/yb',
        name: 'yb',
        meta: {
            isShow: false,
            title: '医保支付'
        },
        component: () =>
            import ('../views/paySuccess/components/index/index.vue')
    },
    {
        path: '/feedSubmit',
        name: 'feedSubmit',
        meta: {
            isShow: false,
            title: '意见反馈'
        },
        component: () =>
            import ('../views/feedback/feedSubmit.vue')
    },
    // 医保界面
    {
        path: '/chsPage',
        name: 'chsPage',
        meta: {
            isShow: false,
            title: '医保界面'
        },
        component: () =>
            import ('../views/paySuccess/components/index')
    },
    // 医保界面
    {
        path: '/gpWechart',
        name: 'gpWechart',
        meta: {
            isShow: false,
            title: '跳转界面'
        },
        component: () =>
            import ('../views/gpWechart')
    }
]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    routes
})

// 未关闭本地存储
router.beforeEach((to, from, next) => {
    console.log(111)
        // window.history.replaceState(null, null, '')
        // window.history.replaceState(null, null, window.localtion.href)
    next()
        // let
        // next()

    let gotoHis = to.path
    if (
        gotoHis == '/homeManage' ||
        gotoHis == '/jzHistory' ||
        gotoHis == '/OutpatientDepartment' ||
        gotoHis == '/categoryGh' ||
        gotoHis == '/satisfactionEvaluation' ||
        gotoHis == '/satisfactionEvaluationMz' ||
        gotoHis == '/payRecord' ||
        gotoHis == '/OutpatientDepartmentFirst'
    ) {
        let beifenJX = JSON.parse(window.localStorage.getItem('beifenJX'))
        for (let key in beifenJX) {
            !!beifenJX[key] ? window.sessionStorage.setItem(key, beifenJX[key]) : ''
        }
        window.localStorage.clear()
        window.localStorage.setItem('gotoHis', to.path)
    } else {
        // window.localStorage.clear()
        // window.sessionStorage.setItem('gotoHis', '1234')
    }

    if (to.path == '/departmentIntroduction') {
        next()
    } else if (to.path == '/departmentSpecial') {
        next()
    } else if (to.path == '/famousDoctor') {
        next()
    } else if (to.path == '/specialMess') {
        next()
    } else {
        // return false
        // 让页面回到顶部
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        window.pageYOffset = 0

        let sess = window.sessionStorage.getItem('code')

        // 高平线上
        let appid = config.appid
        let local = window.localStorage.getItem('gotoHis') ? window.location.href : config.local
        const getUrlParam = name => {
            //封装方法
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
            var r = window.location.search.substr(1).match(reg) //匹配目标参数
            if (r != null) return unescape(r[2])
            return null //返回参数值
        }
        let code = getUrlParam('code')
            //
        let useGoOrderFinId = getUrlParam('useGoOrderFinId')
        let useGoOrderFinState = getUrlParam('useGoOrderFinState')
        let orgPatientIdZz = getUrlParam('orgPatientId')
        if (orgPatientIdZz != null) {
            window.sessionStorage.clear()
            window.sessionStorage.setItem('orgPatientIdZk', orgPatientIdZz)
        }
        if (sess != '' && sess != null) {
            // if (window.sessionStorage.getItem('img') == null) {
            // 	getWxAuth({
            // 		code: code
            // 	}).then(res => {
            // 		window.sessionStorage.setItem('img', res.data.data.head)
            // 		window.sessionStorage.setItem('nickname', res.data.data.name)
            // 		window.sessionStorage.setItem('sex', res.data.data.sex)
            // 		window.sessionStorage.setItem('token', res.data.data.token)
            // 	})
            // }
        } else {
            if (from.name == 'set') {
                next()
            } else {
                if (code == null) {
                    let zzz =
                        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
                        appid +
                        '&redirect_uri=' +
                        encodeURIComponent(local) +
                        '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
                    location.replace(zzz)
                } else {
                    Dialog.alert({
                        title: '登录提示',
                        message: '登录中...',
                        showConfirmButton: false,
                        width: 190
                    })
                    console.log(122)
                    window.sessionStorage.setItem('code', code)
                    getWxAuth({
                        code: window.sessionStorage.getItem('code')
                    }).then(res => {
                        window.sessionStorage.setItem('img', res.data.head)
                        window.sessionStorage.setItem('nickname', res.data.name)
                        window.sessionStorage.setItem('sex', res.data.sex)
                        window.sessionStorage.setItem('token', res.data.token)
                        window.sessionStorage.setItem('openid', res.data.openid)
                        console.log(store)
                        store.commit("setToken", res.data.token)
                        let beifenJX = JSON.parse(window.localStorage.getItem('beifenJX'))
                        for (let key in beifenJX) {
                            !!beifenJX[key] ? window.sessionStorage.setItem(key, beifenJX[key]) : ''
                        }
                        patientUser().then(res => {
                            if (res.data != null) {
                                window.sessionStorage.setItem('idCard', res.data.idCard)
                                window.sessionStorage.setItem('name', res.data.name)
                                if (window.localStorage.getItem('gotoHis').includes('/homeManage')) {
                                    next('/homeManage')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/jzHistory') {
                                    next('/jzHistory')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/OutpatientDepartment') {
                                    next('/OutpatientDepartment')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/categoryGh') {
                                    console.log(123)
                                        // next('/categoryGh')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/OutpatientDepartmentFirst') {
                                    next('/OutpatientDepartmentFirst')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/satisfactionEvaluation') {
                                    next('/satisfactionEvaluation')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/satisfactionEvaluationMz') {
                                    next('/satisfactionEvaluation')
                                    window.sessionStorage.setItem('mzmyd', '1')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/payRecord') {
                                    next('/payRecord')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else {
                                    window.localStorage.clear()
                                    window.location.reload()
                                }
                            } else {
                                if (window.localStorage.getItem('gotoHis').includes('/homeManage')) {
                                    next('/homeManage')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/jzHistory') {
                                    next('/jzHistory')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/OutpatientDepartment') {
                                    next('/OutpatientDepartment')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/categoryGh') {
                                    next('/categoryGh')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/OutpatientDepartmentFirst') {
                                    next('/OutpatientDepartmentFirst')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/satisfactionEvaluation') {
                                    // next('/satisfactionEvaluation')
                                    next({ path: '/satisfactionEvaluation', query: { name: 'zymyd' }, replace: true })
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/satisfactionEvaluationMz') {
                                    // next('/satisfactionEvaluation')
                                    next({ path: '/satisfactionEvaluation', query: { name: 'mzmyd' }, replace: true })
                                    window.sessionStorage.setItem('mzmyd', '1')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else if (window.localStorage.getItem('gotoHis') == '/payRecord') {
                                    next('/payRecord')
                                    Dialog.close()
                                    window.localStorage.clear()
                                } else {
                                    window.localStorage.clear()
                                    window.location.reload()
                                }
                            }
                        })
                    })
                    next()
                }
            }
        }
        next()
    }
})
export default router